@import "./vendor/bootstrap-4.3.1/bootstrap.scss";
@import "./var";
@import "./mixins";

// font Icon
@import "./iconFonts";

@import "./loader";

//Master Slider css
@import "../js/vendor/masterslider/masterslider.main.css";

//Onepage
@import "../js/vendor/onepage-scroll/onepage-scroll.css";

//Slick Slider
@import "../js/vendor/slick/slick.scss";

//Home Scss
@import "./home";

// Career Scss
@import "./career";


//Blogs scss
@import "./blogs";

//Our Expertise Scss
@import "./our-expertise";

:root {
    font-size: 10px;
}

html,
body {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    font-family: $base-font;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: .3px;

    @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
    }
}

h1 {
    font-size: 4vh;
}

h2 {
    font-size: 3.5vh;
}

h3 {
    font-size: 3vh;
}

h4 {
    font-size: 2.5vh;
}

h5 {
    font-size: 2vh;
}

h6 {
    font-size: 1.6vh;
}

/* ========= start Common =========*/

a,
a:hover,
a:focus {
    outline: none;
    text-decoration: none;
    transition: 300ms ease;
}

input,
input:focus {
    font-size: 1.4rem;
    outline: none;
    box-shadow: none;
}

button,
button:focus {
    outline: none;
    transition: 300ms ease;
    cursor: pointer;
}

.single-section {
    padding-top: 5rem;

    .container {
        height: calc(100% - 5rem);
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;

        .container {
            height: 100%;
        }
    }
}

.section-title {
    margin: 0 0 3vh;

    strong {
        color: $honeyColor;
    }

    span {
        max-width: 100%;
        width: 700px;
        display: block;
        margin: 1.5vh auto;
        font-size: 1.6vh;
        font-weight: 400;
        color: #14a7df;
        line-height: 1.5;
    }
}

.pop-overlay {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.58);
    z-index: 5;
}

.overflow-off {
    overflow: hidden !important;
}

@media(min-width: 1024px) and (max-width: 1325px) {
    .container {
        max-width: 100%;
        width: 100%;
    }
}

/* ========= End Common =========*/

/* ========= Start Header =========*/

#main-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    transition: 300ms ease;

    .logo-header {
        width: 18rem;
        display: flex;
        align-items: center;
        font-size: 7rem;
        line-height: 8rem;

        img {
            width: 100%;
        }
    }

    .nav-socials {
        position: relative;

        .head-social {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 50%;
            right: 4rem;
            transform: translateY(-50%);

            a {
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 5px;
                border-radius: 50%;
                font-size: 1.8rem;
                transition: 300ms ease 600ms;

                img {
                    width: 2rem;
                }

                &.ext-link {
                    width: auto;
                    height: auto;
                    margin: 0;
                    padding: 0 1rem;
                    color: #000000;
                    font-size: 1.4rem;
                    font-weight: 500;

                    &.seprator {
                        position: relative;
                        margin-left: 2rem;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    &:hover {
                        color: $honeyColor;
                        transition: 300ms ease 0s;
                    }
                }
            }
        }

        .head-nav {
            display: flex;
            align-items: center;
            margin-right: 4rem;
            visibility: hidden;

            a {
                display: block;
                margin: 0 1rem;
                margin-left: -2rem;
                color: #000;
                font-weight: 500;
                text-align: center;
                transform: translateX(20px);
                opacity: 0;
                @include cubicTransision1(opacity, transform);

                &:hover {
                    color: $honeyColor;
                    transition: 300ms ease;
                }
            }

            &.closing {
                a {
                    margin: 0 1rem;
                    transform: translateX(-30px);
                    opacity: 0;
                }
            }
        }

        &.open-nav {
            .head-social {
                a {
                    transform: rotate(90deg) scale(0);
                    opacity: 0;
                    transition: 300ms ease 0s;
                }
            }

            .head-nav {
                visibility: visible;

                a {
                    margin: 0 1rem;
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }

    .btn-menu-burger {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0;
        border: none;
        box-shadow: none;
        background: transparent;
        overflow: hidden;
        text-align: center;

        span {
            background-color: #000000;
            transition: 300ms ease;
        }

        .up-1,
        .up-2 {
            width: 18px;
            height: 1px;
            display: block;
            margin: 0 auto;
        }

        .up-1 {
            margin-bottom: 5px;
        }

        .up-2 {
            margin-top: 5px;
        }

        .close-1,
        .close-2 {
            width: 25px;
            height: 1px;
            display: block;
            margin: 0 auto;
        }

        .close-2 {
            margin-top: -1px;
        }

        &.active {
            .up-1 {
                opacity: 0;
                transform: translateY(-20px);
                @include cubicTransision1(opacity, transform);
            }

            .up-2 {
                opacity: 0;
                transform: translateY(20px);
                @include cubicTransision1(opacity, transform);
            }

            .close-1 {
                transform: rotate(50deg);
                transition: 300ms ease 300ms;
            }

            .close-2 {
                transform: rotate(-50deg);
                transition: 300ms ease 300ms;
            }
        }
    }

    &.fixed-top {
        background-color: rgba(36, 54, 142, 0.88);
        box-shadow: 0 1px 6px -4px rgba(0, 0, 0, .3);

        .logo-header {
            i {
                &:before {
                    color: #ffffff;
                }
            }
        }

        .head-social,
        .head-nav {
            a {
                color: #ffffff;

                i {
                    &:before {
                        color: #ffffff;
                    }
                }

                &.ext-link {
                    color: #ffffff;
                }
            }
        }

        .btn-menu-burger {
            span {
                background-color: #ffffff;
            }
        }
    }

    &.transparancy {
        background-color: transparent;
        box-shadow: none;
    }

    @include media-breakpoint-down(md) {
        overflow: hidden;

        .container {
            justify-content: space-between
        }

        .logo-header {
            font-size: 5rem;
        }

        .nav-socials {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            position: absolute;
            top: 10rem;
            left: 0;

            .head-social {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                position: static;
                transform: translate(0);

                a {
                    opacity: 0;
                    transition: 300ms ease 0s;

                    &:nth-child(4) {
                        order: 0;
                    }

                    &:nth-child(5) {
                        order: 1;
                    }

                    &:nth-child(1) {
                        order: 2;
                    }

                    &:nth-child(2) {
                        order: 3;
                    }

                    &:nth-child(3) {
                        order: 4;
                    }

                    &.ext-link {
                        width: 100%;
                        margin: 0;
                        padding: .5rem 0;
                        border-bottom: 1px solid #139dd2;
                        border-radius: 0;
                        color: #ffffff;
                        font-size: 1.3rem;

                        &.seprator {
                            margin: 0;
                        }
                    }

                    &:not(.ext-link) {
                        margin-top: 10vh;

                        i {
                            &:before {
                                color: #ffffff
                            }
                        }
                    }
                }
            }

            .head-nav {
                display: flex;
                flex-direction: column;
                margin: 0;

                a {
                    width: 100%;
                    color: #ffffff;
                    margin-top: -2rem;
                    padding: .5rem 0;
                    font-size: 1.3rem;
                    transform: translate(0, -20px);
                }
            }

            &.open-nav {
                .head-social {
                    a {
                        transform: rotate(0) scale(1);
                        opacity: 1;
                        transition: 300ms ease 0s;
                    }
                }

                .head-nav {
                    a {
                        margin: 0;
                        border-bottom: 1px solid #139dd2;
                        transform: translate(0);
                    }
                }
            }
        }

        &.opened {
            height: 100%;
            background: linear-gradient(to bottom, rgba(20, 167, 223, 1) 0%, rgba(36, 54, 142, 1) 100%);

            .logo-header {
                font-size: 8rem;

                i {
                    &:before {
                        color: #ffffff;
                    }
                }
            }

            .btn-menu-burger {
                span {
                    background-color: #ffffff;
                }
            }
        }
    }
}

/* ======== End Header ======== */

/* ======== Start Footer ======== */
#main-footer {
    width: 100%;
    background-color: rgba(62, 78, 154, 0.9);
    color: #ffffff;
    text-align: center;
    z-index: 5;
    transition: 300ms ease;

    p {
        margin: 0;
        padding: 1rem 0;
        font-size: 1rem;
    }

    &.foot-up {
        bottom: 0;
    }
}

/* ======== End Footer ======== */

/* ======== Start Default Form Design ======== */

.default-frm-group {
    position: relative;
    margin-top: 2.5rem;
    z-index: 1;

    label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0 .7rem;
        color: #848484;
        transform-origin: left;
        transition: 300ms ease;
        z-index: 1;
    }

    input,
    select,
    textarea {
        width: 100%;
        height: 36px;
        padding: 1rem 1.5rem;
        border: none;
        border-radius: 0;
        background: transparent;
        font-size: 1.3rem;
        resize: none;

        &:focus {
            background: transparent;
            outline: none;
            box-shadow: none;
        }
    }

    textarea {
        height: 80px;

        +label {
            align-items: flex-start;
        }
    }

    &:before,
    &:after {
        height: 1px;
        content: "";
        position: absolute;
        left: 0;
        transition: 300ms ease;
        z-index: -1;
    }

    &:before {
        width: 0;
        border-top: 1px solid $primaryColor;
    }

    &:after {
        width: 100%;
        bottom: 0;
        border-bottom: 1px solid #000000;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    }

    &.field-focused {
        label {
            width: auto;
            height: auto;
            top: -10px;
            left: 10px;
            background-color: #ffffff;
            color: $primaryColor;
            transform: scale(.78);
        }

        &:before {
            width: 100%;
        }

        &:after {
            height: 100%;
            border-color: $primaryColor;
        }
    }
}

.btn-submit {
    display: block;
    position: relative;
    margin: 4rem auto 0;
    padding: .8rem 5rem;
    border: 2px solid $secondaryColor;
    background-color: transparent;
    color: $secondaryColor;
    font-size: 1.6rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.02s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
    z-index: 1;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &::before {
        left: 0;
        right: 100%;
        bottom: 0;
        background: $secondaryColor;
        transition: 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1), left 0.3s cubic-bezier(0.1, 0, 0.1, 1);
        z-index: -1;
    }

    &::after {
        left: calc(100% - 7rem);
        right: 7rem;
        bottom: 0;
        background-image: url("../img/icons/icon-arrow.svg  ");
        background-size: 1.5em;
        background-repeat: no-repeat;
        background-position: center;
        transition: right 0.3s cubic-bezier(0.1, 0, 0.1, 1);
    }

    &:hover {
        padding: .8rem 8.5rem .8rem 1.5rem;

        &::before {
            left: calc(100% - 7rem);
            right: 0;
            transition: 0.3s cubic-bezier(0.1, 0, 0.1, 1), left 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
        }

        &:after {
            right: 0;
            transition: right 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
        }
    }
}

.input-upfile {
    max-width: 100%;
    text-align: left;

    input[type='file'] {
        display: none;
    }

    .file-info {
        font-size: 0.9em;
    }

    .browse-btn {
        background: $primaryColor;
        color: #fff;
        min-height: 35px;
        margin-right: 10px;
        padding: 8px 12px;
        border: none;

        &:hover {
            background: rgba($primaryColor, .7);
        }
    }

    @media (max-width: 300px) {
        .browse-btn {
            width: 100%;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0;
        }

        .file-info {
            display: block;
            margin: 10px 5px;
        }
    }
}

/* ======== End Default Form Design ======== */

/* ======== Start Default Jiggle Pop Design ======== */
.default-jiggle-pop {
    max-width: calc(100% - 6rem);
    max-height: 80vh;
    width: 132vh;
    height: 80vh;
    display: flex;
    position: fixed;
    top: calc(50% + 3rem);
    left: 50%;
    padding: 0 15px;
    text-align: left;
    transform: translate(-50%, -50%);
    visibility: hidden;
    z-index: 6;

    .btn-close-jiggle-pop {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2rem;
        right: 2rem;
        border: 1px solid #ffffff;
        border-radius: 50%;
        background-color: #ffffff;
        color: #000000;
        z-index: 1;
    }

    .jiggle-pop-imgs {
        height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: radial-gradient(ellipse at center, rgba(43, 101, 124, 1) 11%, rgba(3, 14, 26, 1) 100%);
        overflow: hidden;

        figure {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 25rem;

                &.hide-pop-img {
                    display: none;
                }
            }

            &.multi {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                position: relative;
                margin: 0;
                padding-right: 4rem;

                img {
                    width: 30rem;
                    display: block;
                    margin-left: auto;
                    opacity: 0;
                    transform: translate(0, -100px);

                    &.img-mac {
                        width: 22rem;
                        position: absolute;
                        bottom: 0.5rem;
                        left: 0;
                        transform: translate(-100px, 0);
                    }

                    &.img-ipad {
                        width: 10rem;
                        position: absolute;
                        bottom: 0.5rem;
                        right: 0;
                        transform: translate(100px, 0);
                    }
                }
            }

        }
    }

    .jiggle-details {
        max-height: 100%;
        height: 0;
        align-self: flex-end;
        padding: 0;
        background: linear-gradient(to right, rgba(242, 242, 242, 1) 0%, rgba(233, 231, 232, 1) 100%);
        color: #777777;
        overflow: hidden;

        * {
            opacity: 0;
            transform: translateY(50px);
        }

        .jiggle-pop-title {
            margin: 0 0 3rem;
            color: $honeyColor;
            font-weight: 500;
            text-align: center;

            span {
                display: block;
                margin: 1rem 0 0;
                color: #8c8c8c;
                font-size: 1.2rem;
                line-height: 1.6;
            }
        }

        h6 {
            margin: 1.5rem 0 1rem;
            color: $secondaryColor;
            font-size: 1.5rem;

            &+* {
                padding-left: 2rem;
            }

            &+ul {
                padding-left: 3.5rem;
            }
        }

        p,
        ul {
            margin-bottom: 1.5rem;
        }

        ul {
            list-style: none;

            li {
                position: relative;
                margin: 0 0 .8rem;
                display: flex;
                align-items: center;

                &:before {
                    width: 2rem;
                    content: "\2022";
                    position: absolute;
                    top: 1.1rem;
                    left: -2rem;
                    color: #20317e;
                    font-weight: 700;
                    display: inline-block;
                    font-size: 3rem;
                    line-height: 0;
                }
            }
        }
    }

    &.jiggle-pop-active {
        visibility: visible;

        .jiggle-pop-imgs {
            height: 100%;
            padding: 3rem;
            transition: 400ms ease;

        }

        .jiggle-details {
            height: 100%;
            padding: 5rem 4rem;
            transition: 400ms ease;
        }
    }

    &.anime-pop-content {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);

        .jiggle-pop-imgs {
            figure {

                &.multi {
                    img {
                        opacity: 1;
                        transform: translate(0);
                        transition: opacity .3s ease 200ms, transform .4s ease-out;
                    }
                }
            }
        }

        .jiggle-details {
            overflow: auto;

            * {
                opacity: 1;
                transform: translate(0);
                transition: opacity .3s ease 100ms, 400ms ease-out;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .jiggle-pop-imgs {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        max-width: calc(100% - 4rem);
        max-height: calc(100% - 4rem);
        height: calc(100% - 4rem);
        top: 50%;

        &.jiggle-pop-active {
            .jiggle-details {
                height: 100%;
                padding: 5rem 2rem;
            }
        }
    }
}

/* ======== End Default Jiggle Pop Design ======== */